import React, { useRef, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import GlobalStyles, { TitleLarge700 } from '@nebula/global-styles';
import defaultTheme from '@nebula/theme';
import { InfoCardExtended } from '@nebula/cards';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';
import { NeutralHeavyLozengeBadge, NeutralLightLozengeBadge, badgeSizeEnum } from '@nebula/badge';
import { TextLinkBack } from '@nebula/text-link';

import AlertsCustomerFeedback from '../AlertsCustomerFeedback';
import AlertsPersonalDetailsCard from '../AlertsPersonalDetailsCard';
import { EnvConsumer } from '../Context/env';
import getCreditLockCardText from './getCreditLockCardText';
import { pushECD2Event } from '../../helpers/pushECD2Event';
import getAlertType from '../../helpers/getAlertType';
import getCLButtonProperties from './getCreditLockButtonProperties';
import getPersonalDetailsHeader from './getPersonalDetailsHeader';
import {
  AlertDetailsHeaderWrapper,
  AlertDetailsHeader,
  AlertDetailsHeaderTitleBox,
  AlertDetailsSubheader,
  AlertDetailsDescriptionWrapper,
  BackButton,
  ContentWrapper,
  ContentHeader,
  RecogniseWrapper,
  InstantAlertContainer,
  FeedbackWrapper,
  FeedbackButtonWrapper,
  MainWrapper,
  Data,
  AlertDetailsDescriptionHeader,
  AlertDetailsIntro,
  ActionCardsWrapper,
  StyledHeaderTwo,
  AlertFeedbackWrapper,
} from './index.styles';
import getBadgeTextAndType from './getBadgeTextAndType';
import Option1LockFeedback from './Option1LockFeedback';
import NotrecognisedFeedback from './NotRecognisedFeedback';
import CreditLockCard from '../CreditLockCard';

const InstantAlertDetails = ({ alertDetail, shouldReferenceFraudReport, testVariant }) => {
  const alertType = getAlertType(alertDetail?.search);

  const pushGoToCreditLockECD2Event = () => {
    // remove GA3 event in Q2FY25
    pushECD2Event({
      Category: 'Alerts Product Usage',
      Action: 'Alert Detail',
      Label: 'Go to CreditLock',
      AlertType: alertType,
    });

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: 'id_alerts_lock_cta',
        action: 'click',
        status: 'success',
        data: { alertType },
      })
    );
  };

  const [selectedOption2, setSelectedOption2] = useState(null);
  const sendFeedback = () => {
    setSelectedOption2(true);
    Analytics.publishOnce(
      InteractionInputEvent.fromObject({
        component: 'do_not_recognise_stopped_application',
        action: 'click',
        status: 'success',
      })
    );
  };

  const { renderBadge, badgeText, badgeType } = getBadgeTextAndType(alertDetail?.search || {});
  const { renderCreditLockModule, creditLockHeader, creditLockSubtext } = getCreditLockCardText(
    alertDetail?.search || {}
  );
  const { creditLockButtonText, creditLockButtonKind, creditLockButtonHref } = getCLButtonProperties(
    alertDetail?.search || {}
  );
  const personalDetailsHeader = getPersonalDetailsHeader(alertDetail?.search || {});
  const searchTypeUpperCase = (alertDetail?.search?.searchType || '').toUpperCase();
  const renderOption1Test = testVariant === 'Option1' && alertDetail?.search?.isBlocked;
  const renderOption2Test = testVariant === 'Option2' && alertDetail?.search?.isBlocked;
  const notRecognisedActionsRef = useRef(null);
  const notRecognisedActionsId = 'not-recognised-actions';

  return alertDetail?.search && !alertDetail.error ? (
    <EnvConsumer>
      {context => (
        <InstantAlertContainer>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <AlertDetailsIntro>
              <BackButton>
                <TextLinkBack href="/alerts">Alert center</TextLinkBack>
              </BackButton>
              <AlertDetailsHeaderWrapper>
                <AlertDetailsHeader>
                  <AlertDetailsHeaderTitleBox>
                    <StyledHeaderTwo>{alertDetail.search.searchedBy}</StyledHeaderTwo>
                  </AlertDetailsHeaderTitleBox>
                  <AlertDetailsSubheader>
                    <Data>{alertDetail.search.searchedOn}</Data>
                    <AlertDetailsDescriptionWrapper>
                      <AlertDetailsDescriptionHeader>
                        <p {...setAutomationElement('header-description')}>
                          {alertDetail.search.applicationType} {searchTypeUpperCase === 'H' ? 'application' : ''}
                        </p>
                      </AlertDetailsDescriptionHeader>
                    </AlertDetailsDescriptionWrapper>
                    {renderBadge && (
                      <div {...setAutomationElement('header-badge')}>
                        {badgeType === 'primary' && (
                          <NeutralHeavyLozengeBadge text={badgeText} badgeSize={badgeSizeEnum.medium} />
                        )}
                        {badgeType === 'tertiary' && (
                          <NeutralLightLozengeBadge text={badgeText} badgeSize={badgeSizeEnum.medium} />
                        )}
                      </div>
                    )}
                  </AlertDetailsSubheader>
                </AlertDetailsHeader>
              </AlertDetailsHeaderWrapper>
            </AlertDetailsIntro>
            <ContentWrapper>
              {renderOption1Test && (
                <AlertFeedbackWrapper>
                  <Option1LockFeedback
                    {...setAutomationElement('stopped-alert-module')}
                    notRecognisedActionsRef={notRecognisedActionsRef}
                    notRecognisedActionsId={notRecognisedActionsId}
                  />
                </AlertFeedbackWrapper>
              )}
              {renderOption2Test && selectedOption2 && (
                <NotrecognisedFeedback
                  {...setAutomationElement('stopped-alert-module2')}
                  notRecognisedActionsRef={notRecognisedActionsRef}
                  notRecognisedActionsId={notRecognisedActionsId}
                />
              )}
              <MainWrapper>
                <ContentHeader>
                  <TitleLarge700 {...setAutomationElement('personal-details-header')}>
                    {personalDetailsHeader}
                  </TitleLarge700>
                </ContentHeader>
                <AlertsPersonalDetailsCard
                  name={alertDetail.name}
                  dob={alertDetail.dob}
                  address={alertDetail.address}
                />
                {renderCreditLockModule && (
                  <CreditLockCard
                    creditLockButtonHref={creditLockButtonHref}
                    creditLockButtonKind={creditLockButtonKind}
                    creditLockHeader={creditLockHeader}
                    creditLockSubtext={creditLockSubtext}
                    creditLockButtonText={creditLockButtonText}
                    pushGoToCreditLockECD2Event={pushGoToCreditLockECD2Event}
                    selectedOption2={selectedOption2}
                    renderOption2Test={renderOption2Test}
                    sendFeedback={sendFeedback}
                  />
                )}
              </MainWrapper>
              <RecogniseWrapper
                id={notRecognisedActionsId}
                ref={notRecognisedActionsRef}
                {...setAutomationElement(notRecognisedActionsId)}
              >
                <ContentHeader>
                  <TitleLarge700>What should I do if I don’t recognise this?</TitleLarge700>
                </ContentHeader>
                <ActionCardsWrapper>
                  <InfoCardExtended
                    title="Look at your credit report"
                    description="Your report will give extra information about the search that may help you recognise it."
                    testId="alerts-action-link-to-credit-report"
                    href={shouldReferenceFraudReport ? '/report' : `${context.REACT_APP_INS_URL}/report`}
                  />
                  <InfoCardExtended
                    title="Contact us"
                    description="If you’re concerned and need help, please get in touch. You could also contact the lender."
                    testId="alerts-action-link-to-contact-page"
                    href={`${context.REACT_APP_INS_URL}/contact`}
                  />
                  {searchTypeUpperCase === 'H' && (
                    <InfoCardExtended
                      title="Visit Victims of Fraud"
                      description="Visit our Victims of Fraud page for further help and steps you can take to help protect yourself."
                      testId="alerts-action-link-to-victims-of-fraud"
                      href={`${context.REACT_APP_EXPERIAN_URL}/consumer/identity/what-to-do-if-victim.html`}
                    />
                  )}
                </ActionCardsWrapper>
              </RecogniseWrapper>
              <FeedbackWrapper>
                <ContentHeader>
                  <TitleLarge700>Was this information helpful?</TitleLarge700>
                </ContentHeader>
                <FeedbackButtonWrapper>
                  <AlertsCustomerFeedback alertType={alertType} />
                </FeedbackButtonWrapper>
              </FeedbackWrapper>
            </ContentWrapper>
          </ThemeProvider>
        </InstantAlertContainer>
      )}
    </EnvConsumer>
  ) : null;
};

InstantAlertDetails.propTypes = {
  alertDetail: PropTypes.shape({
    address: PropTypes.string,
    dob: PropTypes.string,
    error: PropTypes.bool,
    name: PropTypes.string,
    search: PropTypes.shape({
      applicationType: PropTypes.string,
      searchedOn: PropTypes.string,
      searchedBy: PropTypes.string,
      searchType: PropTypes.string,
      isBlocked: PropTypes.bool,
      isLocked: PropTypes.bool,
      isLockExcluded: PropTypes.bool,
    }),
  }),
  feedbackAlertType: PropTypes.string,
  shouldReferenceFraudReport: PropTypes.bool,
  testVariant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

InstantAlertDetails.defaultProps = {
  alertDetail: {},
  feedbackAlertType: '',
  shouldReferenceFraudReport: false,
  testVariant: false,
};

export default InstantAlertDetails;
