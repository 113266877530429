import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Link } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import { Container, Inner } from './index.styles';
import addEvent from '../../actions/ecd/addEvent';
import browserLinks from './browserLinks';
import appLinks from './appLinks';
import testOutdated from './testOutdated';

export default () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  if (pathname !== '/' || !testOutdated()) return null;

  // remove GA3 event in Q2FY25
  dispatch(addEvent('/', { action: 'Unsupported Browser HomePage', category: 'Identity App Home Page' }));

  Analytics.publish(
    InteractionInputEvent.fromObject({
      component: 'id_home_outdated_browser',
      action: 'view',
      status: 'success',
    })
  );

  return (
    <Container>
      <Inner>
        Unfortunately, we won’t be able to support your browser soon. To get the best experience possible please update
        your browser or upgrade to{' '}
        <Link.Anchor inline href={browserLinks.edge} rel="noopener noreferrer" target="_blank">
          Edge
        </Link.Anchor>
        ,{' '}
        <Link.Anchor inline href={browserLinks.chrome} rel="noopener noreferrer" target="_blank">
          Chrome
        </Link.Anchor>
        , or{' '}
        <Link.Anchor inline href={browserLinks.firefox} rel="noopener noreferrer" target="_blank">
          Firefox
        </Link.Anchor>
        . Alternatively, download our Experian App for{' '}
        <Link.Anchor href={appLinks.android} rel="noopener noreferrer" target="_blank">
          Android
        </Link.Anchor>{' '}
        or{' '}
        <Link.Anchor href={appLinks.ios} rel="noopener noreferrer" target="_blank">
          iOS
        </Link.Anchor>
        .
      </Inner>
    </Container>
  );
};
