import { SplitFactory } from '@splitsoftware/splitio';
import { splitsForNav } from '@nebula/navigation';
import Cookies from 'js-cookie';
import { getEnv } from '../components/Context/env';
import {
  SPLIT_CLIENT_FAILED,
  SPLIT_CLIENT_READY,
  SPLITS_FETCHED,
} from '../actions/split';
import isServer from '../helpers/isServer';
import { mockSplit } from '../helpers/test/mocks';
import { splitsList } from '../constants';
import initialize from '../actions/ecd/initialize';

const treatments = [...Object.values(splitsList), ...splitsForNav];
let splitInitialised = false;

export default (env = getEnv(), onServer = isServer()) => store => next => (action) => {
  const { split } = store.getState();

  if (!split.key || split.ready || split.error || splitInitialised) {
    return next(action);
  }

  splitInitialised = true;

  const defaultSplitApiKey = 'localhost';
  const shouldUseLocalMode = (onServer || env.REACT_APP_ENV === 'local' || (window && window.Cypress));
  const apiKey = shouldUseLocalMode ? defaultSplitApiKey : env.REACT_APP_SPLIT_API_KEY;

  const factory = SplitFactory({
    core: {
      authorizationKey: apiKey || defaultSplitApiKey,
      key: split.key || 'customerNumber',
    },
    features: mockSplit(split.key),
    startup: {
      readyTimeout: 1.5,
    },
  });

  const client = factory.client();

  client.on(client.Event.SDK_READY, () => {
    store.dispatch({
      type: SPLIT_CLIENT_READY,
    });

    const optimConsent = Cookies.get('EXPERIAN_ENSIGHTEN_PRIVACY_Performance_and_Analytics') === '1'
      && Cookies.get('EXPERIAN_ENSIGHTEN_PRIVACY_Advertising') === '1';

    store.dispatch({
      type: SPLITS_FETCHED,
      payload: client.getTreatmentsWithConfig(treatments, { optimConsent }) || {},
    });

    store.dispatch(initialize());
  });

  client.on(client.Event.SDK_READY_TIMED_OUT, () => {
    store.dispatch({
      type: SPLIT_CLIENT_FAILED,
    });
  });

  return next(action);
};
