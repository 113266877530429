import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { setAutomationElement } from '@experian-uk/corvetteuk-common-ui';
import Analytics, { InteractionInputEvent } from '@experian-uk/web-common-analytics';

import addEcdEvent from '../../../../actions/ecd/addEvent';
import { CustomUtilLink } from './Link';

@connect(null, dispatch =>
  bindActionCreators(
    {
      addEcdEvent,
    },
    dispatch
  )
)
export default class CPPContactLink extends React.Component {
  static propTypes = {
    addEcdEvent: PropTypes.func.isRequired,
    baseUrl: PropTypes.string,
  };

  static defaultProps = {
    baseUrl: '/',
  };

  handleClick = () => {
    // remove GA3 event in Q2FY25
    this.props.addEcdEvent('/', {
      category: 'CPP Identity Page',
      action: 'Support Pages - Click',
      label: 'Contact Link (Header)',
    });

    Analytics.publish(
      InteractionInputEvent.fromObject({
        component: 'id_header_contact',
        action: 'click',
        status: 'success',
      })
    );
  };

  render() {
    return (
      <CustomUtilLink
        href={`${this.props.baseUrl}/support`}
        key="contact"
        onClick={this.handleClick}
        {...setAutomationElement('headerContact')}
      >
        Contact & FAQ
      </CustomUtilLink>
    );
  }
}
