import React, { useEffect } from 'react';
import Navigation, { getNavData, splitsForNav } from '@nebula/navigation';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from '@nebula/global-styles';
import defaultTheme from '@nebula/theme';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pushECD2Event } from '../../../helpers/pushECD2Event';
import getAlertCountAction from '../../../actions/alerts/getCount';

const Header = ({
  splits, getAlertCount, alerts, subscriptionDetails, productDetails, currentUser, boostStatus,
}) => {
  useEffect(() => {
    getAlertCount();
  }, []);


  const splitList = Object.keys(splits)
    .filter(split => splitsForNav.includes(split))
    .map(splitKey => ({ splitName: splitKey, treatment: splits[splitKey] ? 'on' : 'off' }));


  let helperInput;
  if (!subscriptionDetails.current) {
    helperInput = {
      splitNamesAndTreatments: [],
      domain: window.location.origin,
    };
  } else {
    helperInput = {
      alertsInformation: {
        count: alerts.unread,
      },
      boostInformation: {
        hasProposition: boostStatus?.hasProposition,
      },
      customerInformation: {
        benefits: subscriptionDetails.current.benefits,
        family: subscriptionDetails.current.family,
        isWithinTrial: productDetails.isFreeTrial,
        name: {
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
        },
      },
      domain: window.location.origin,
      splitNamesAndTreatments: splitList,
    };
  }


  const navData = getNavData(helperInput);

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <Navigation data={navData} raiseEcd={pushECD2Event} />
    </ThemeProvider>
  );
};

Header.propTypes = {
  alerts: PropTypes.shape({
    unread: PropTypes.number,
    error: PropTypes.bool.isRequired,
  }).isRequired,
  boostStatus: PropTypes.shape({
    hasProposition: PropTypes.bool,
  }),
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  getAlertCount: PropTypes.func.isRequired,
  splits: PropTypes.shape({}),
  subscriptionDetails: PropTypes.shape({
    current: PropTypes.shape({
      benefits: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          benefitActivated: PropTypes.bool,
        }),
      ),
      family: PropTypes.string,
    }),
  }),
  productDetails: PropTypes.shape({
    isFreeTrial: PropTypes.bool,
  }),
};

Header.defaultProps = {
  boostStatus: {},
  currentUser: {},
  splits: {},
  subscriptionDetails: {},
  productDetails: {},
};

const mapReduxStateToProps = state => ({
  alerts: state.alerts,
  boostStatus: state.boost.data,
  currentUser: state.auth.currentUser,
  productDetails: state.productDetails,
  splits: state.split.treatments,
  subscriptionDetails: state.subscriptions,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getAlertCount: getAlertCountAction,
}, dispatch);

export default connect(
  mapReduxStateToProps,
  mapDispatchToProps,
)(Header);
